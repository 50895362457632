<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card" id="card_section">
          <div class="card-title p-3 py-2 border-bottom">
            <div class="d-lg-flex align-items-center">
              <div class="me-2 d-inline-block">
                <i class="bi bi-arrow-up-circle display-6 text-primary"></i>
              </div>
              <div class="mb-2 mb-lg-0 d-inline-block">
                <h4 class="fw-medium font-size-20 text-dark mb-0">
                  {{ title }}
                </h4>
              </div>

              <div class="ms-auto">
                <button
                  class="btn btn-outline-info fw-medium me-2"
                  @click="$refs.modalFunc.showModal()"
                  v-if="account_type!=='reseller' && create_modify_permission"
                >
                  <i class="uil uil-plus font-size-16 me-1"></i> Make a Payout
                </button>
                <button class="btn btn-outline-info fw-medium" @click="handlePageChange(currentPage)">
                  <i class="uil uil-redo font-size-16 me-1"></i> Refresh
                </button>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-12 col-md-4 mb-3">
                <label class="form-label mb-1">Merchant Contract</label>
                <multiselect
                  ref="select"
                  v-model="selectedmerchantContract"
                  :options="merchantContract"
                  label="account_name"
                  placeholder="Select"
                  :close-on-select="true"
                  :allow-empty="false"
                  deselect-label=""
                  :show-on-select="true"
                  :disabled="disabled"
                  @open="isOpen = true"
                  @close="isOpen = false"
                  @input="handlePageChange(1)"
                >
                  <template slot="singleLabel" slot-scope="props">
                    <span v-if="props.option.contract_id !== ''">
                      <span
                        v-if="props.option.contract_status == 'unavailable'"
                        class="text-warning me-1"
                        >&#9679;</span
                      >
                      <span
                        v-else-if="props.option.contract_status == 'available'"
                        class="text-success me-1"
                        >&#9679;</span
                      >
                      <span class="option__title">
                        {{ props.option.account_name }}:
                        {{ props.option.contract_name }}
                      </span>
                    </span>
                    <span v-else>{{ props.option.contract_name }} </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <span v-if="props.option.contract_id !== ''">
                      <span
                        v-if="props.option.contract_status == 'unavailable'"
                        class="text-warning me-1"
                        >&#9679;</span
                      >
                      <span
                        v-else-if="props.option.contract_status == 'available'"
                        class="text-success me-1"
                        >&#9679;</span
                      >
                      <span class="option__title">
                        {{ props.option.account_name }}:
                        {{ props.option.contract_name }}
                      </span>
                    </span>
                    <span v-else>{{ props.option.contract_name }}</span>
                  </template>
                  <span slot="noResult">Oops! No Contract found. </span>
                </multiselect>
              </div>
              <div class="col-12 col-md-8">
                <div class="row">
                  <div class="col-12 col-md-6 col-xl-4 px-md-1 mb-3">
                    <label class="form-label mb-1">Status</label>
                    <select
                      class="form-select pe-5"
                      id="1"
                      v-model="selectedStatus"
                      @change="handlePageChange(1)"
                      :disabled="disabled"
                    >
                      <option value="">All Status</option>
                      <option value="pending">Pending</option>
                      <option value="reserved">Reserved</option>
                    </select>
                  </div>
                  <div class="col-12 col-md-6 col-xl-4 px-md-1 mb-3">
                    <label class="form-label mb-1">Country</label>
                    <multiselect
                      v-model="selectedCountry"
                      :options="countryList"
                      label="name"
                      class=""
                      placeholder="Select"
                      :close-on-select="true"
                      :allow-empty="false"
                      deselect-label=""
                      select-label=""
                      :show-on-select="true"
                      :disabled="disabled"
                      @input="countryChanged"
                    >
                      <span slot="noResult">Oops! No Country found. </span>
                    </multiselect>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md mb-3">
                <label class="form-label mb-1">Payout Id</label>
                <div>
                  <input
                    type="text"
                    placeholder="Payout Id"
                    class="form-control"
                    :disabled="disabled"
                    v-model.trim="searchPayoutId"
                    @keyup.enter="search()" 
                  />
                </div>
              </div>
              <div class="col-12 col-md mb-3">
                <label class="form-label mb-1">Reference No.</label>
                <div>
                  <input
                    type="text"
                    placeholder="Reference No."
                    class="form-control"
                    :disabled="disabled"
                    v-model.trim="searchReferenceNo"
                    @keyup.enter="search()" 
                  />
                </div>
              </div>
              <div class="col-12 col-md mb-3">
                <label class="form-label mb-1">Amount</label>
                <div>
                  <input
                    type="tel"
                    @input="inputNumberOnly($event, 'searchAmount')"
                    placeholder="Amount"
                    class="form-control"
                    :disabled="disabled"
                    v-model.trim="searchAmount"
                    @keyup.enter="search()" 
                  />
                </div>
              </div>
              <div class="col-12 col-md-auto text-md-end mb-3 mb-lg-0">
                <label class="form-label mb-1 d-md-block d-none">&nbsp;</label>
                <div class="d-flex w-100">
                  <button
                    class="btn btn-light col-auto mb-0 me-2 text-nowrap d-grid"
                    type="button"
                    :disabled="disabled"
                    @click="clear()"
                  >
                    <span><i class="uil-redo me-1"></i> Clear</span>
                  </button>
                  <button
                    class="btn btn-info text-white me-0 text-nowrap d-grid"
                    type="button"
                    :disabled="disabled"
                    @click="search()"
                  >
                    <span><i class="uil-search me-1"></i> Search</span>
                  </button>
                </div>
              </div>
              <div class="col-12 m-0 p-0"></div>
              <div class="col-12 col-lg-12 col-xl d-none">
                <label class="form-label mb-1">Filter By</label>
                <div class="input-group mb-3">
                  <select class="form-select pe-5" v-model="filterType">
                    <option value="created_date">Created Date</option>
                    <option value="updated_date">Updated Date</option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-md col-xl mb-0 d-none">
                <label class="form-label mb-1">Date Range</label>
                <date-picker
                  v-model="date_range"
                  type="date"
                  range
                  value-type="format"
                  format="YYYY-MM-DD"
                  placeholder="Select Date Range"
                  :disabled="disable_date"
                  :disabled-date="notBeforeToday"
                  :clearable="false"
                  :editable="false"
                  :shortcuts="shortcuts"
                  class="mb-3"
                ></date-picker>
                <!-- 
                  @change="DateChanged()" -->
              </div>

              <div class="col-12 col-md-auto col-xl-auto d-none">
                <label class="form-label mb-1 d-md-block d-none">&nbsp;</label>
                <div class="row">
                  <div class="col-6 pe-1 col-auto d-grid">
                    <button
                      class="btn btn-light mb-3 text-nowrap"
                      type="button"
                      :disabled="disabled"
                      @click="clear()"
                    >
                      <i class="uil-redo me-1"></i> Clear
                    </button>
                  </div>
                  <div class="col-6 ps-1 col-auto d-grid">
                    <button
                      class="btn btn-info text-white mb-3 text-nowrap"
                      type="button"
                      :disabled="disabled"
                      @click="search()"
                    >
                      <i class="uil-search me-1"></i> Search
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-12 col-xl">
                <div class="float-end">
                  <div class="mb-3 ml-auto d-none">
                    <b-button
                      type="submit"
                      variant="info"
                      @click="exportData()"
                      :disabled="disableExport"
                    >
                      <i class="uil-file-export me-1"></i>
                      Export
                      <div
                        class="spinner-border spinner-border-sm text-white"
                        v-if="loadingButton"
                        role="status"
                      ></div>
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="table-responsive text-nowrap font-size-13 position-relative custom-table"
            >
              <table
                id="freeze"
                class="table mt-2 mb-0 align-middle min-width-760"
                :class="{ 'table-hover': returnData.length > 0 && !loading }"
              >
                <thead class="text-uppercase">
                  <tr>
                    <th>Payout Id<br>Initiator</th>
                    <th>Reference No.<br />Merchant Item ID</th>
                    <th>Created Date<br />Updated Date</th>
                    <th>Merchant<br />Contract</th>
                    <th>Receiving Bank<br />Beneficiary Name/Acc No.</th>
                    <th>Amount</th>
                    <th>Total Charges</th>
                    <th>Amount Deducted</th>
                    <th>Status</th>
                    <th class="text-center" v-if="account_type == 'admin' && create_modify_permission">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!returnData.length && !loading">
                    <td
                      :colspan="(account_type === 'admin' && create_modify_permission) ? 10 : 9"
                      height="300"
                      class="text-center text-muted"
                    >
                      <i class="bi bi-arrow-up-circle me-1"></i> No Data
                      Available
                    </td>
                  </tr>
                  <tr v-if="loading">
                    <td
                      :colspan="(account_type === 'admin' && create_modify_permission) ? 10 : 9"
                      class="text-center text-muted"
                      height="400"
                    >
                      <div
                        class="spinner-border text-secondary my-2"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                      <br />
                      <div>Loading...</div>
                    </td>
                  </tr>

                  <tr v-for="(value, index) in returnData" :key="index">
                    <td>
                      {{ value.payout_id }}<br>
                      <span class="badge bg-soft-purple font-size-13 mt-1 text-capitalize">  {{ value.initiator }} </span>
                    </td>
                    <td>
                      {{ value.reference_no || '-' }}<br>
                      {{ value.merchant_item_id || '-' }}
                    </td>
                    <td>{{ value.created_at }}<br />{{ value.updated_at || '-' }}</td>
                    <td>
                      <div v-if="account_type == 'admin' && value.merchant_info">
                        <router-link
                          :to="{
                            name: 'merchants',
                            params: { id: value.upline_db_code },
                          }"
                          >#{{ value.merchant_info.merchant_id }}
                          {{ value.merchant_info.merchant_name }}</router-link
                        >
                      </div>
                      <div v-else-if="value.merchant_info">
                        #{{ value.merchant_info.merchant_id }}
                        {{ value.merchant_info.merchant_name }}
                      </div>

                      <router-link
                        v-if="value.merchant_info"
                        :to="{
                          name: 'contract ledger with id',
                          params: {
                            resellerid: 'all',
                            id: value.merchant_info.merchant_contract,
                          },
                        }"
                        >{{ value.merchant_info.merchant_contract }}:
                        {{ value.merchant_info.merchant_contract_name }}
                      </router-link>

                    </td>
                    <td>
                      <div class="fw-medium text-dark">
                        {{ value.receiving_bank }}
                      </div>
                      <div v-if="value.receiving_bank">
                        {{ value.receiver_account_name }}<br />{{
                          value.receiver_account_number
                        }}
                      </div>
                      <div v-else>-</div>

                      
                    </td>
                    <td>
                      {{ value.payout_currency }}
                      <span v-if="value.payout_amount">{{ convertCurrencyFormat(value.payout_amount,true) }}</span>
                    </td>
                    <td>
                      {{ value.payout_currency }}
                      <span v-if="value.payout_total_charges">{{ convertCurrencyFormat(value.payout_total_charges,true) }}
                        <span class="text-muted font-size-12 d-block">  {{ value.payout_rate }}% + {{ value.payout_currency }}  {{ value.payout_extra_charges }}</span>
                      </span>
                    </td>
                    <td>
                      {{ value.payout_currency }} 
                      <span v-if="value.amount_deducted">{{ convertCurrencyFormat(value.amount_deducted,true) }}</span>
                    </td>
                    <td>
                      <span
                        class="badge bg-soft-purple rounded-pill font-size-13"
                        v-if="value.status == 'processing'"
                        >Processing</span
                      >
                      <span
                        class="badge bg-soft-warning rounded-pill font-size-13"
                        v-else-if="value.status == 'pending'"
                        >Pending</span
                      >
                      <span
                        class="badge bg-soft-info rounded-pill font-size-13"
                        v-else-if="value.status == 'reserved'"
                        >Reserved</span
                      >
                    </td>
                    <td v-if="account_type == 'admin' && create_modify_permission" class="text-center">
                      <span v-if="value.process_by == 'system'" class="text-start text-muted"
                        >Proccesing by System</span
                      >
                      <button
                        type="button"
                        v-else-if="value.status == 'pending'"
                        class="btn btn-outline-info px-2 py-1 font-size-15 rounded"
                        :disabled="loading"
                        @click="updatePayout(value)"
                      >
                        <i class="uil uil-upload"></i>
                      </button>
                      <button
                        type="button"
                        v-else-if="
                          value.status == 'reserved' &&
                          value.processed_by == accessUsername
                        "
                        class="btn btn-outline-info px-2 py-1 font-size-15 rounded"
                        :disabled="loading"
                        @click="updatePayout(value)"
                      >
                        <i class="uil uil-upload"></i>
                      </button>
                      
                      <span v-else class="text-start text-muted"
                        >This action is only<br />allowed for
                        {{ value.processed_by }}</span
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row" v-if="returnData.length && !loading">
              <div class="col mt-3">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      hide-ellipsis
                      @change="handlePageChange"
                      v-model="currentPage"
                      :per-page="perPage"
                      :total-rows="totalData"
                    >
                    </b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Lottie
      :path="'/animate/loading_purple.json'"
      :title="'Loading'"
      :info="'Please wait...'"
      :show="showLottie"
      ref="lottieFunc"
    />
    <Common ref="commonFunc" />
    <MakePayout
      ref="modalFunc"
      :data="{ accessUsername: accessUsername, accessToken: accessToken }"
      @callParentFunction="handlePageChange(currentPage)"
    />
    <UpdatePayout
      ref="modalFunc3"
      :data="{
        accessUsername: accessUsername,
        accessToken: accessToken,
        updateData: updateData,
      }"
      @callParentFunction="handlePageChange(currentPage)"
    />
    <ImportPayout
      ref="modalFunc2"
      :data="{ accessUsername: accessUsername, accessToken: accessToken }"
      @callParentFunction="handlePageChange(currentPage)"
    />
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import MakePayout from "@/views/pages/app/modal/createPayout";
import UpdatePayout from "@/views/pages/app/modal/updatePayoutV2";
import ImportPayout from "@/views/pages/app/modal/importPayout";
/**
 * Page
 */
const PageTitle = "Pending Payouts";

const CancelToken = axios.CancelToken;
let cancel;

const CountryDefault = { name: "All Countries", value: "", status: "" };
const MerchantDefault = {
  status: "0",
  payin_rate: 0,
  contract_id: "",
  payout_rate: 0,
  total_payin: 0,
  ewallet_rate: 0,
  total_payout: 0,
  contract_name: "All Contracts",
  contract_total: 0,
  contract_status: "",
  settlement_rate: 0,
  contract_balance: 0,
  total_settlement: 0,
  contract_currency: "",
  payin_extra_charges: 0,
  payout_extra_charges: 0,
  ewallet_extra_charges: 0,
  settlement_extra_charges: 0,
  contract_list_length_total: 0,
  merchant_Id: "",
  account_name: "",
  merchant_status: "",
  reseller_id: "",
  reseller_name: "",
  reseller_code: "",
  reseller_level: 0,
};
export default {
  components: {
    Layout,
    PageHeader,
    Lottie,
    Common,
    DatePicker,
    Multiselect,
    MakePayout,
    UpdatePayout,
    ImportPayout,
  },
  page() {
    return {
      title: PageTitle,
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    };
  },
  data() {
    return {
      accessToken: "",
      accessEmail: "",
      accessPhone: "",
      accessUsername: "",
      account_type: "",
      showLottie: false,
      title: "",
      items: [
        {
          text: appConfig.pageTitle,
          to: "/",
        },
        {
          text: "",
          active: true,
        },
      ],
      formData: {
        apiID: "YOUR_API_ID",
        apiHash: "YOUR_API_HASH",
      },
      updateData: {},
      submitted: false,
      loading: false,
      buttonLoading: [false, false, false],
      currentPage: 1,
      currencySymbol: "₹",
      selectedmerchantContract: MerchantDefault,
      merchantContract: [MerchantDefault],
      selectedCountry: CountryDefault,
      countryList: [CountryDefault],
      seletedMethod: "",
      selectedStatus: "",
      searchPayoutId: "",
      searchReferenceNo: "",
      filterType: "created_date",
      searchAmount: "",
      perPage: 20,
      totalData: 0,
      returnData: [],
      returnPaidTotal: 0,
      returnPayoutProcessed: 0,
      export_data: [],
      search_value: "",
      disableExport: false,
      loadingButton: false,
      date_range: null,
      disable_date: false,
      disabled: false,
      shortcuts: [],
      selectedMerchant: "M00001",
      currentMerchantInfo:{},
      currentResellerInfo:{},
      create_modify_permission:false
    };
  },
  middleware: "authentication",
  async mounted() {
    this.title = PageTitle;
    this.items[1].text = PageTitle;
    // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
    // this.$refs.main.changeVerticalTopBar("bill",true)
    // this.$refs.main.setShowFooterCert(false)
    // this.$refs.main.setPageTitle('title')
    await this.reload();
    this.accessToken = this.$refs.commonFunc.getToken();
    this.accessUsername = this.$refs.commonFunc.getUsername();
    this.date_range = this.$refs.commonFunc.last30Days();
    this.shortcuts = this.$refs.commonFunc.datePickerShortcut();
    this.getMerchantContract();
    this.getCountries();
    await this.handlePageChange(1);
  },
  created() {},
  methods: {
    updatePayout(value) {
      this.updateData = value;
      setTimeout(() => this.$refs.modalFunc3.showModal(), 100);
    },
    convertCurrencyFormat(value, show00) {
      if (show00 == true) {
        return this.$refs.commonFunc.convertCurrencyFormat(value);
      } else {
        // Assuming this.transactionLimit is defined in your component's data
        return this.$refs.commonFunc
          .convertCurrencyFormat(value)
          .replaceAll(".00", "");
      }
    },
    inputNumberOnly(event, parentModel) {
      const numericValue = event.target.value.replace(/[^0-9.]/g, "");
      this[parentModel] = numericValue;
    },
    customLabel1({ name, id, shortName }) {
      if (id == -1) {
        return name;
      } else {
        return `#${id}  ${name} (${shortName})`;
      }
    },
    countryChanged() {
      //this.selectedBanks=BankDefault
      this.handlePageChange(1);
    },
    async getCountries() {
      console.log("getCountries");
      this.$Progress.start();
      this.loading = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      axios({
        method: "post",
        url: appConfig.APIHostAdmin + "controller/admin/getSupportedCurrency",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            (this.selectedCountry = CountryDefault),
              (this.countryList = [this.selectedCountry]);
            resData.data.forEach((element) => {
              this.countryList.push(element);
            });
          } else if (resData.status == 440) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
            this.loading = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            this.loading = false;
          }
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    getMerchantContract() {
      console.log("getMerchantContract");
      this.$Progress.start();
      this.loading = true;
      this.disabled = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("page", "1");
      bodyFormData.append("limit", "9999999");
      bodyFormData.append("reseller", (this.account_type==='reseller') ? this.currentResellerInfo.account_db_code : "all" ) 
      bodyFormData.append("merchant", (this.account_type==='merchant') ? this.currentMerchantInfo.account_db_code : "all" );
      axios({
        method: "post",
        url:
          appConfig.APIHostAdmin +
          "controller/admin/getContractListForMerchant",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            this.selectedmerchantContract = MerchantDefault;
            this.merchantContract = [this.selectedmerchantContract];

            resData.data.forEach((element) => {
              this.merchantContract.push(element);
            });
          } else if (resData.status == 440) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          }
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    notBeforeToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    DateChanged() {
      console.log("datechange" + this.date_range);
      this.handlePageChange(1);
      this.currentPage = 1;
      this.disable_date = true;
    },
    handlePageChange(current_page) {
      this.pageNum = current_page;
      this.currentPage = current_page;
      this.getData(this.pageNum, this.search_value);
      //this.exportData(1)
    },

    search() {
      this.handlePageChange(1);
      this.currentPage = 1;
    },
    clear() {
      this.selectedCountry = CountryDefault;
      this.selectedmerchantContract = MerchantDefault;
      this.seletedMethod = "";
      this.selectedStatus = "";
      this.searchPayoutId = "";
      this.searchReferenceNo = "";
      this.filterType = "created_date";
      this.searchAmount = "";
      this.date_range = this.$refs.commonFunc.last30Days();

      this.search_value = "";
      this.handlePageChange(1);
      this.currentPage = 1;
    },

    exportToExcel(Data, name, type) {
      var title = name.replace(/ /g, "_");
      var ws = window.XLSX.utils.json_to_sheet(Data);
      let objectMaxLength = [];
      for (let i = 0; i < Data.length; i++) {
        let value = Object.values(Data[i]);
        for (let j = 0; j < value.length; j++) {
          if (typeof value[j] == "number") {
            objectMaxLength[j] = 5;
          } else {
            objectMaxLength[j] =
              objectMaxLength[j] >= value[j].length
                ? parseInt(objectMaxLength[j])
                : parseInt(value[j].length) + 5;
          }
        }
      }
      var wscols = [];
      objectMaxLength.forEach((value, index) => {
        console.log(value);
        wscols.push({ width: objectMaxLength[index] });
      }),
        (ws["!cols"] = wscols);

      var wb = window.XLSX.utils.book_new();
      window.XLSX.utils.book_append_sheet(wb, ws, name);
      window.XLSX.writeFile(wb, title + "." + (type || "xlsx"));
    },
    async getData(pages, keyword) {
      if (cancel !== undefined) cancel();
      this.$Progress.start();
      this.loading = true;
      this.disabled = true;
      this.disable_date = true;
      this.returnData = [];
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("page", pages);
      bodyFormData.append("limit", this.perPage);
      bodyFormData.append("status", this.selectedStatus);
      // bodyFormData.append("dateStart", this.date_range[0]);
      // bodyFormData.append("dateEnd", this.date_range[1]);
      bodyFormData.append("dateStart",'');
      bodyFormData.append("dateEnd", '');
      bodyFormData.append("merchantContractId", this.selectedmerchantContract.contract_id);
      bodyFormData.append("payoutId", this.searchPayoutId);
      bodyFormData.append("currency", this.selectedCountry.value);
      bodyFormData.append("referenceId", this.searchReferenceNo);
      bodyFormData.append("transactionId", "");
      bodyFormData.append("amount", this.searchAmount);
      console.log(
        this.selectedmerchantContract.contract_id,
        this.seletedMethod,
        this.selectedStatus,
        this.selectedCountry.value,
        this.searchPayoutId,
        this.searchReferenceNo,
        this.searchAmount,
        this.filterType,
        keyword,
        this.date_range[0],
        this.date_range[1]
      );
      axios({
        method: "post",
        url: appConfig.APIHostAdmin + 'controller/admin/getAllPendingPayoutList',
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
        cancelToken: new CancelToken((c) => (cancel = c)),
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            this.returnData = [];
            this.returnData = resData.data;
            this.totalData = resData.total;
          } else if (resData.status == 440) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            // localStorage.clear();
            // this.$router.push({
            //   path: "/login",
            // });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          }
          this.loading = false;
          this.disabled = false;
          this.disable_date = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading = false;
          this.disabled = false;
          this.disable_date = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    async exportData() {
      this.$Progress.start();
      this.disableExport = true;
      this.loadingButton = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("phone", this.accessPhone);
      // bodyFormData.append("page", pages);
      // bodyFormData.append("limit", this.perPage);
      axios({
        method: "get",
        url: `https://dummyjson.com/users/search?q=&limit=100&skip=1`,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          this.export_data = [];
          var resData = response.data;
          this.totalData = resData.total;
          var users = resData.users;

          for (var i in users) {
            // Combine date and time
            const depositDateTime = `29 Jun 2023, 12:30:00`;
            // Generate a random deposit amount
            const randomDeposit = Math.floor(Math.random() * 10000) + 1000; // Random amount between 1000 and 11000

            // Calculate merchant fee and nett amount
            const merchantFeePercentage = 2.5 / 100; // 2.5% merchant fee
            const merchantFee = Math.floor(
              randomDeposit * merchantFeePercentage
            );
            const nettAmount = randomDeposit - merchantFee;
            const randomStatus = Math.random() < 0.8 ? "success" : "failed";
            var randomMode = Math.random() < 0.8 ? "UPI" : "IMPS";
            var transaction_id = "";
            if (randomMode == "UPI") {
              transaction_id = "320020673657";
            } else {
              transaction_id = "IMPS/123443211240";
            }

            const listData = {
              id: "ids",
              deposit_date: depositDateTime,
              customer_id: `${users[i].id}`,
              customer_name: `${users[i].firstName} ${users[i].lastName}`,
              deposit_amount: randomDeposit.toString(),
              deposit_mode: randomMode.toString(),
              merchant_fee: merchantFee.toString(),
              nett_amount: nettAmount.toString(),
              transaction_id: transaction_id,
              status: randomStatus,
            };
            this.export_data.splice(i, 0, listData);
          }
          var self = this;
          setTimeout(function () {
            self.disableExport = false;
            self.loadingButton = false;
            self.exportToExcel(self.export_data, "Summary", "xlsx");
          }, 1200);

          // if (resData.status == 200) {
          //   console.log(resData)
          //   this.totalData = 10
          // }
          // else if (resData.status == 440){
          //   Swal.fire(
          //       {
          //       icon: 'error',
          //       title: 'Oops...',
          //       html: `${resData.message}.`,
          //       confirmButtonColor: '#222',
          //       confirmButtonText: this.$t('siteLang.Done'),
          //     })
          //     localStorage.clear();
          //     this.$router.push({
          //         path: "/login",
          //   });
          // }
          // else {
          //     Swal.fire(
          //       {
          //       icon: 'error',
          //       title: 'Oops...',
          //       html: `${resData.message}.`,
          //       confirmButtonColor: '#222',
          //       confirmButtonText: this.$t('siteLang.Done'),
          //     })
          // }
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.disableExport = false;
          this.loadingButton = false;
          this.loading = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted

        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.currentMerchantInfo = data.merchant_info;
          this.currentResellerInfo = data.reseller_info;
          this.account_type = data.account_type;
          const permission = data.modules.filter(e => e.name==='payout')
          if (permission) {
            this.create_modify_permission = permission[0].create_modify_permission==0 ? false : true; 
          } else {
            console.log('Module with name "payout" not found.');
          }
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },
  },
};
</script>